import { ApolloClient } from 'apollo-client';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, from } from 'apollo-link';
import * as Sentry from '@sentry/react';

import Environment from './Environment';
import GraphQLTags from './GraphQLTags';
import Logger from './Logger';
import Auth from './Auth';
import BBAnalytics from './BBAnalytics';

const shouldRetry = (error, _operation) => {
  if (
    _operation &&
    _operation.query &&
    _operation.query.definitions &&
    _operation.query.definitions.length &&
    _operation.query.definitions[0].operation === 'query'
  ) {
    return true;
  }
  return false;
};

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, _operation) => shouldRetry(error, _operation),
  },
});
const httpLink = new HttpLink({
  uri: Environment.getDomain,
  credentials: 'include',
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  const tempTokenForGiftPurchase = localStorage.getItem(
    'tempTokenForGiftPurchase',
  );
  operation.setContext({
    headers: {
      authorization:
        token || tempTokenForGiftPurchase
          ? `Bearer ${token || tempTokenForGiftPurchase}`
          : '',
    },
  });
  return forward(operation);
});

const checkIfShouldHandleAsInfo = (message) => {
  return (
    message.startsWith('Oops!') ||
    message.includes('EMAIL_EXISTS') ||
    message.includes('STRIPE_ERROR')
  );
};

const checkIfShouldHandleAsWarning = (message) => {
  return (
    message.startsWith('Load failed') || message.startsWith('Failed to fetch')
  );
};

const authAfterware = onError(({ graphQLErrors, networkError, operation }) => {
  if (
    graphQLErrors &&
    graphQLErrors.length &&
    graphQLErrors[0].message.match(/InvalidToken/)
  ) {
    Auth.clearAndRedirect();
  } else if (graphQLErrors && graphQLErrors.length) {
    graphQLErrors.forEach((error) => {
      const { message, path, extensions } = error;
      const shouldHandleAsInfo = checkIfShouldHandleAsInfo(message);
      const errorMessage = `GraphQL Error: ${message} at ${path}; code: ${extensions.code}`;
      if (shouldHandleAsInfo) {
        Logger.info(errorMessage, extensions.exception);
      } else {
        Logger.error(errorMessage, extensions.exception);
      }
    });
  } else if (networkError) {
    const { message, statusCode } = networkError;
    let errorMessage = message;
    if (operation && operation.operationName && statusCode) {
      errorMessage = `${message} with status ${statusCode} at ${operation.operationName}`;
    } else if (operation && operation.operationName) {
      errorMessage = `${message} at ${operation.operationName}`;
    } else if (statusCode) {
      errorMessage = `${message} with status ${statusCode}`;
    }
    const shouldHandleAsWarning = checkIfShouldHandleAsWarning(errorMessage);
    if (shouldHandleAsWarning) {
      Logger.warn(errorMessage);
    } else {
      Logger.error(errorMessage);
    }
  }
  return null;
});

const persistedQueryLink = createPersistedQueryLink({
  useGETForHashedQueries: true,
});

const link = from([
  retryLink,
  authMiddleware,
  authAfterware,
  persistedQueryLink,
  httpLink,
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
// Attaching the client to the window object so that it can be accessed across the app.
window.apolloClient = client;

function storeToken(token) {
  localStorage.removeItem('tempTokenForGiftPurchase');
  localStorage.setItem('token', token);
}

const _clientMutation = (data) => {
  const mutationData = data;
  if (Auth.isAdmin()) {
    const customerId = localStorage.getItem('customerId');
    if (!customerId) throw new Error('Could not get customer id for admin.');
    if (!mutationData.variables) {
      mutationData.variables = {};
    }
    mutationData.variables.customerIdForAdmin = customerId;
  }
  return client.mutate({
    mutation: mutationData.mutation,
    variables: mutationData.variables,
  });
};

const _clientQuery = (data) => {
  const queryData = data;
  if (Auth.isAdmin()) {
    const customerId = localStorage.getItem('customerId');
    if (!customerId) throw new Error('Could not get customer id for admin.');
    if (!queryData.variables) {
      queryData.variables = {};
    }
    queryData.variables.customerIdForAdmin = customerId;
  }
  return client.query({
    query: queryData.query,
    variables: queryData.variables,
    fetchPolicy: queryData.fetchPolicy || '',
  });
};

const populateProductCosts = (cart) => {
  const tempCart = cart;
  tempCart.productsAndDetails.forEach((productDetailsItem, index) => {
    if (productDetailsItem.isBundledGWPProduct) {
      tempCart.productsAndDetails[index].amountToCharge = parseFloat(
        productDetailsItem.product.ownPromoData.bundledGWPCost.toFixed(2),
        10,
      );
    } else if (
      productDetailsItem.promoItem &&
      productDetailsItem.product.ownPromoData
    ) {
      tempCart.productsAndDetails[index].amountToCharge = parseFloat(
        productDetailsItem.product.ownPromoData.cost.toFixed(2),
        10,
      );
    } else if (productDetailsItem.isAddedCouponProduct) {
      tempCart.productsAndDetails[index].amountToCharge = parseFloat(0, 10);
    } else {
      tempCart.productsAndDetails[index].amountToCharge = parseFloat(
        (
          productDetailsItem.product.actualCost * productDetailsItem.quantity
        ).toFixed(2),
        10,
      );
    }
  });
  return tempCart;
};

const getTempCart = function getTempCart() {
  const tempCartAsString = localStorage.getItem('tempCart');
  const emptyCart = { productsAndDetails: [], subscription: null };
  let tempCart;
  if (
    tempCartAsString &&
    tempCartAsString !== 'null' &&
    tempCartAsString !== 'undefined'
  ) {
    tempCart = JSON.parse(tempCartAsString);
  }

  if (
    !tempCart ||
    (tempCart &&
      !('productsAndDetails' in tempCart || 'subscription' in tempCart))
  ) {
    const tempCartString = JSON.stringify(emptyCart);
    localStorage.setItem('tempCart', tempCartString);
    tempCart = emptyCart;
  }

  if (tempCart.productsAndDetails && tempCart.productsAndDetails.length) {
    tempCart = populateProductCosts(tempCart);
  }

  return tempCart;
};

function getTempCartAsString() {
  const tempCart = getTempCart();
  return JSON.stringify(tempCart);
}

function getCosts(cart) {
  const { productsAndDetails } = cart;
  const { subscription } = cart;
  let subCost = 0;
  if ((!productsAndDetails || !productsAndDetails.length) && !subscription)
    return 0;
  if (subscription) {
    subCost = subscription.plan.costWithCoupon;
  }
  return productsAndDetails.reduce((cost, productAndDetailsItem) => {
    if (
      productAndDetailsItem.promoItem &&
      productAndDetailsItem.product.ownPromoData
    ) {
      return productAndDetailsItem.product.ownPromoData.cost + cost;
    }
    return (
      productAndDetailsItem.product.actualCost *
        parseInt(productAndDetailsItem.quantity, 10) +
      cost
    );
  }, subCost);
}

const productsHaveEquivalentSpecialStatus = (
  productsAndDetailsItem,
  currentItem,
) => {
  return (
    !!productsAndDetailsItem.promoItem === !!currentItem.promoItem &&
    !!productsAndDetailsItem.isAddedCouponProduct ===
      !!currentItem.isAddedCouponProduct &&
    !!productsAndDetailsItem.isBOGOItem === !!currentItem.isBOGOItem &&
    !!productsAndDetailsItem.isDmeAddOn === !!currentItem.isDmeAddOn
  );
};

const findIndexForItemWithoutColorOrVariation = (
  newProductsAndDetails,
  currentItem,
) => {
  return newProductsAndDetails.findIndex(
    (productsAndDetailsItem) =>
      productsAndDetailsItem.product._id.toString() ===
        currentItem.product._id.toString() &&
      productsHaveEquivalentSpecialStatus(productsAndDetailsItem, currentItem),
  );
};

const findIndexForItemWithColorAndOrVariation = (
  newProductsAndDetails,
  currentItem,
) => {
  return newProductsAndDetails.findIndex(
    (productsAndDetailsItem) =>
      productsAndDetailsItem.product._id.toString() ===
        currentItem.product._id.toString() &&
      ((productsAndDetailsItem.color === currentItem.color &&
        currentItem.color !== 'NONE') ||
        (productsAndDetailsItem.variation === currentItem.variation &&
          currentItem.variation !== 'NONE')) &&
      productsHaveEquivalentSpecialStatus(productsAndDetailsItem, currentItem),
  );
};

const setQuantityToOneForSpecialItems = (cartItems) => {
  cartItems.forEach((productsAndDetailsItem) => {
    if (
      productsAndDetailsItem.promoItem ||
      productsAndDetailsItem.isAddedCouponProduct
    ) {
      // eslint-disable-next-line no-param-reassign
      productsAndDetailsItem.quantity = 1;
    }
  });
};

const combineDuplicateProducts = (productsAndDetails) => {
  const cartItems = productsAndDetails.reduce(
    (newProductsAndDetails, currentItem) => {
      let productIndex;
      if (!newProductsAndDetails.length) {
        newProductsAndDetails.push(currentItem);
      } else {
        if (currentItem.variation === 'NONE' && currentItem.color === 'NONE') {
          productIndex = findIndexForItemWithoutColorOrVariation(
            newProductsAndDetails,
            currentItem,
          );
        } else {
          productIndex = findIndexForItemWithColorAndOrVariation(
            newProductsAndDetails,
            currentItem,
          );
        }

        if (productIndex >= 0) {
          // eslint-disable-next-line no-param-reassign
          newProductsAndDetails[productIndex].quantity += currentItem.quantity;
        } else {
          newProductsAndDetails.push(currentItem);
        }
      }
      return newProductsAndDetails;
    },
    [],
  );

  setQuantityToOneForSpecialItems(cartItems);

  return cartItems;
};

function addItemToTempCart(newCartItem, type, list) {
  const cartItems = getTempCart();
  if (
    (newCartItem.__typename && newCartItem.__typename === 'Subscription') ||
    type === 'Subscription'
  ) {
    cartItems.subscription = newCartItem;
    // adds autopopulated coupon and addedProducts to temp cart
    const couponToUse =
      newCartItem.subscriptionCouponFromURL ||
      newCartItem.plan?.couponToAutoPopulate;
    if (couponToUse) {
      cartItems.coupon = couponToUse;
      if (couponToUse.addedProducts?.length) {
        couponToUse.addedProducts.forEach((couponProduct) => {
          const addedProduct = {
            isAddedCouponProduct: true,
            product: couponProduct,
            color: couponProduct.color || 'NONE',
            variation: couponProduct.variation || 'NONE',
            quantity: 1,
            promoItem: false,
          };
          cartItems.productsAndDetails.push(addedProduct);
        });
      }
      if (couponToUse.addedProductsOptions?.length) {
        const defaultCouponProduct = couponToUse.addedProductsOptions[0];
        const addedProduct = {
          isAddedCouponProduct: true,
          product: defaultCouponProduct,
          color: defaultCouponProduct.color || 'NONE',
          variation: defaultCouponProduct.variation || 'NONE',
          quantity: 1,
          promoItem: false,
        };
        cartItems.productsAndDetails.push(addedProduct);
      }

      if (couponToUse.planInfoOverride) {
        cartItems.subscription.plan.costWithCoupon =
          couponToUse.planInfoOverride.costWithCoupon;
      }
    }
  } else {
    if (
      cartItems.subscription &&
      newCartItem.product &&
      newCartItem.product.ownPromoData &&
      newCartItem.product.ownPromoData.isRecurringAddOn &&
      newCartItem.promoItem
    ) {
      if (!cartItems.subscription.recurringAddOns) {
        cartItems.subscription.recurringAddOns = [];
      }
      cartItems.subscription.recurringAddOns.push(newCartItem);
    }
    cartItems.productsAndDetails.push(newCartItem);

    cartItems.productsAndDetails = combineDuplicateProducts(
      cartItems.productsAndDetails,
    );
  }
  localStorage.setItem('tempCart', JSON.stringify(cartItems));
  const overallTotal = getCosts(cartItems);

  BBAnalytics.updatedCartEvent(
    {
      productsAndDetails: cartItems.productsAndDetails,
      subscription: cartItems.subscription,
      subCosts: { overallTotal },
    },
    null,
    newCartItem, // this is the itemToAdd
    list,
  );
  return cartItems;
}

function setRavenUserContext(user) {
  // Logging should not halt processing so it is wrapped in a try/catch
  try {
    Sentry.setUser({
      email: user.email,
      name: `${user.name.first} ${user.name.last}`,
    });
  } catch (err) {
    console.log('Could not configure error logging', err); // eslint-disable-line no-console
  }
}

const signInUser = (data) => {
  const existingUserData = data;
  const tempCart = getTempCart();
  if (
    !existingUserData.cartId &&
    (tempCart.productsAndDetails.length || tempCart.subscription)
  ) {
    existingUserData.tempCart = JSON.stringify(tempCart);
  }
  existingUserData.provider = Environment.store;
  return client
    .mutate({
      mutation: GraphQLTags.mutations.signInUser,
      variables: existingUserData,
    })
    .then((response) => {
      const user = response.data.signInUser;
      if (user && user.token) {
        client.resetStore();
        localStorage.removeItem('isGuestCheckout');
        storeToken(user.token);
        setRavenUserContext(user);
      }
      if (user && user.accountType === 'corporate') {
        const { lastPasswordChange } = user;
        if (lastPasswordChange) {
          const lastChanged = new Date(lastPasswordChange).getTime();
          const today = new Date();
          const ninetyDaysAgo = today.setDate(today.getDate() - 90);
          if (lastChanged < ninetyDaysAgo) {
            user.passwordUpdateNeeded = true;
          }
        }
      }
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });
};

const signUpUser = (data) => {
  const newUserData = data;
  const tempCart = getTempCart();
  if (tempCart.productsAndDetails.length || tempCart.subscription) {
    newUserData.tempCart = JSON.stringify(tempCart);
  }
  newUserData.provider = Environment.store;
  return client
    .mutate({
      mutation: GraphQLTags.mutations.signUpUser,
      variables: newUserData,
    })
    .then((response) => {
      client.resetStore();
      const user = response.data.signUpUser;
      if (user && user.token) {
        localStorage.removeItem('isGuestCheckout');
        storeToken(user.token);
        setRavenUserContext(user);
      }
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });
};

const refreshUserToken = () => {
  const data = {};
  data.provider = Environment.store;
  return client
    .query({
      query: GraphQLTags.queries.refreshUserToken,
      variables: data,
      fetchPolicy: 'network-only',
    })
    .then((response) => {
      const user = response.data.refreshUserToken;
      if (user && user.token) {
        storeToken(user.token);
        setRavenUserContext(user);
      }
      return user;
    })
    .catch((err) => {
      if (err?.message?.includes('Unauthorized')) {
        localStorage.removeItem('token');
      }
    });
};

const signOutUser = () =>
  client
    .mutate({
      mutation: GraphQLTags.mutations.signOutUser,
      variables: {},
    })
    .then((response) => response)
    .catch((error) => {
      return { error, result: null };
    });

const confirmUserPassword = (data) => {
  const existingUserData = data;
  existingUserData.provider = Environment.store;

  return client
    .mutate({
      mutation: GraphQLTags.mutations.confirmUserPassword,
      variables: existingUserData,
    })
    .then((response) => {
      return response.data.confirmUserPassword;
    })
    .catch((error) => {
      return { error, result: null };
    });
};

const getEmptyUser = () => {
  const emptyUserData = {};
  const tempCart = getTempCart();
  if (tempCart.productsAndDetails.length || tempCart.subscription) {
    emptyUserData.tempCart = JSON.stringify(tempCart);
  }
  emptyUserData.provider = Environment.store;
  return client
    .mutate({
      mutation: GraphQLTags.mutations.getEmptyUser,
      variables: emptyUserData,
    })
    .then((response) => {
      const user = response.data.getEmptyUser;
      if (user && user.token) {
        storeToken(user.token);
        localStorage.setItem('isGuestCheckout', true);
      }
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });
};

const addDiscountToCart = (discountData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addDiscountToCart,
    variables: discountData,
  })
    .then((response) => {
      const subscription = response.data.addDiscountToCart;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const removeDiscountFromCart = (discountData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.removeDiscountFromCart,
    variables: discountData,
  })
    .then((response) => {
      const subscription = response.data.removeDiscountFromCart;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const editAccountDetails = (data) => {
  const updatedUserData = data;
  updatedUserData.provider = Environment.store;
  return _clientMutation({
    mutation: GraphQLTags.mutations.editAccountDetails,
    variables: updatedUserData,
  })
    .then((response) => response.data.editAccountDetails)
    .catch((error) => {
      return { error, result: null };
    });
};

const editShippingAddress = (updatedShippingData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.editShippingAddress,
    variables: updatedShippingData,
  })
    .then((response) => {
      const subscription = response.data.editShippingAddress;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const editPaymentMethod = (updatedPaymentData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.editPaymentMethod,
    variables: updatedPaymentData,
  })
    .then((response) => {
      const subscription = response.data.editPaymentMethod;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const editBabyDetails = (babyDetailsData) =>
  client
    .mutate({
      mutation: GraphQLTags.mutations.editBabyDetails,
      variables: babyDetailsData,
    })
    .then((response) => {
      const subscription = response.data.editBabyDetails;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const sendForgotPasswordLink = (data) => {
  const emailData = data;
  emailData.provider = Environment.store;
  return _clientMutation({
    mutation: GraphQLTags.mutations.sendForgotPasswordLink,
    variables: emailData,
  })
    .then((response) => {
      const user = response.data.sendForgotPasswordLink;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });
};

const resetPassword = (passwordData) =>
  client
    .mutate({
      mutation: GraphQLTags.mutations.resetPassword,
      variables: passwordData,
    })
    .then((response) => {
      const user = response.data.resetPassword;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });

const skipNextMonth = (skipNextMonthData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.skipNextMonth,
    variables: skipNextMonthData,
  })
    .then((response) => {
      const subscription = response.data.skipNextMonth;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const unskipNextMonth = (unskipNextMonthData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.unskipNextMonth,
    variables: unskipNextMonthData,
  })
    .then((response) => {
      const subscription = response.data.unskipNextMonth;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const addAllShippingData = (shippingData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addAllShippingData,
    variables: shippingData,
  })
    .then((response) => {
      const cart = response.data.addAllShippingData;
      return cart;
    })
    .catch((error) => {
      return { error, result: null };
    });

const addSubscriptionDetails = (subscriptionDetailsData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addSubscriptionDetails,
    variables: subscriptionDetailsData,
  })
    .then((response) => {
      const cart = response.data.addSubscriptionDetails;
      return cart;
    })
    .catch((error) => {
      return { error, result: null };
    });

const reactivateSubscription = (reactivateData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.reactivateSubscription,
    variables: reactivateData,
  })
    .then((response) => {
      const subscription = response.data.reactivateSubscription;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const extendSubscription = (reactivateData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.extendSubscription,
    variables: reactivateData,
  })
    .then((response) => {
      const subscription = response.data.extendSubscription;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const purchaseCart = (data) => {
  const utmSource = localStorage.getItem('utm_source') || '';
  const utmMedium = localStorage.getItem('utm_medium') || '';
  const utmCampaign = localStorage.getItem('utm_campaign') || '';
  const purchaseCartData = { ...data, utmSource, utmCampaign, utmMedium };

  return _clientMutation({
    mutation: GraphQLTags.mutations.purchaseCart,
    variables: purchaseCartData,
  })
    .then((response) => {
      client.resetStore();
      const cart = response.data.purchaseCart;
      return cart;
    })
    .catch((error) => {
      return { error, result: null };
    });
};

const sendCorporateGiftingContactEmail = (data) => {
  const corporateGiftingData = data;
  corporateGiftingData.provider = Environment.store;
  return _clientMutation({
    mutation: GraphQLTags.mutations.sendCorporateGiftingContactEmail,
    variables: corporateGiftingData,
  })
    .then((response) => {
      const user = response.data.sendCorporateGiftingContactEmail;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });
};

const sendEmailToHelloBitsyBoxesWithMessage = (data) => {
  const emailData = data;
  emailData.provider = Environment.store;
  return _clientMutation({
    mutation: GraphQLTags.mutations.sendEmailToHelloBitsyBoxesWithMessage,
    variables: emailData,
  })
    .then((response) => {
      const user = response.data.sendEmailToHelloBitsyBoxesWithMessage;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });
};

const clearClientStore = () => {
  client.clearStore();
};

const addReview = (newReviewData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addReview,
    variables: newReviewData,
  })
    .then((response) => response)
    .catch((error) => {
      return { error, result: null };
    });

const editReview = (newReviewData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.editReview,
    variables: newReviewData,
  })
    .then((response) => {
      const review = response.data.editReview;
      return review;
    })
    .catch((error) => {
      return { error, result: null };
    });

const deleteReview = (reviewToDelete) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.deleteReview,
    variables: reviewToDelete,
  })
    .then((response) => {
      const review = response.data.deleteReview;
      return review;
    })
    .catch((error) => {
      return { error, result: null };
    });

const addItemToCart = (itemToAdd, itemTrackingObj, list) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addItemToCart,
    variables: itemToAdd,
  })
    .then((response) => {
      const cart = response.data.addItemToCart;
      BBAnalytics.updatedCartEvent(cart, itemTrackingObj, itemToAdd, list);
      return cart;
    })
    .catch((error) => {
      return { error, result: null };
    });

const swapGWPItem = (data, itemToAdd) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.swapGWPItem,
    variables: data,
  })
    .then((response) => {
      const cart = response.data.swapGWPItem;
      BBAnalytics.updatedCartEvent(cart, null, itemToAdd);
      return cart;
    })
    .catch((error) => {
      return { error, result: null };
    });

const removeItemFromCart = (itemToRemove) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.removeItemFromCart,
    variables: itemToRemove,
  })
    .then((response) => {
      const cart = response.data.removeItemFromCart;
      BBAnalytics.updatedCartEvent(cart);
      return cart;
    })
    .catch((error) => {
      return { error, result: null };
    });

const addGuestCheckoutData = (guestCheckoutData, trackingSource) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addGuestCheckoutData,
    variables: guestCheckoutData,
  })
    .then((response) => {
      const user = response.data.addGuestCheckoutData;
      BBAnalytics.newUserEvent(user, trackingSource || 'Checkout');
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });

const changeQuantityOfItemInCart = (itemToChange) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.changeQuantityOfItemInCart,
    variables: itemToChange,
  })
    .then((response) => {
      const cart = response.data.changeQuantityOfItemInCart;
      return cart;
    })
    .catch((error) => {
      return { error, result: null };
    });

const removeSubscriptionFromCart = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.removeSubscriptionFromCart,
    variables: data,
  })
    .then((response) => {
      const cart = response.data.removeSubscriptionFromCart;
      BBAnalytics.updatedCartEvent(cart);
      return cart;
    })
    .catch((error) => {
      return { error, result: null };
    });

const updatePointsAppliedStatus = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.updatePointsAppliedStatus,
    variables: data,
  })
    .then((response) => {
      const cart = response.data.updatePointsAppliedStatus;
      return cart;
    })
    .catch((error) => {
      return { error, result: null };
    });

const cancelSubscription = (cancellationData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.cancelSubscription,
    variables: cancellationData,
  })
    .then((response) => {
      const subscription = response.data.cancelSubscription;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const updateRenewalCouponAsUser = (renewalCouponData) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.updateRenewalCouponAsUser,
    variables: renewalCouponData,
  })
    .then((response) => {
      const subscription = response.data.updateRenewalCouponAsUser;
      return subscription;
    })
    .catch((error) => {
      return { error, result: null };
    });

const userInfoQuery = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.userInfoQuery,
    variables: data,
    fetchPolicy: data ? data.fetchPolicy : null,
  })
    .then((response) => response)
    .catch((error) => {
      return { error, result: 0 };
    });

const cartItemCountQuery = () =>
  _clientQuery({
    query: GraphQLTags.queries.cartItemCountQuery,
    variables: {},
  })
    .then((response) => response)
    .catch((error) => {
      return { error, result: 0 };
    });

const cartInfo = (data = {}) => {
  const cartInfoData = data;
  // The intention here is that the referred purchaser will send the referralCode to the backend to be attached to the purchaser's cart when the purchaser goes the the cart review page (or earlier). From that point on, the code will be on the cart itself and will be used to determine if the purchaser is making a legit referral purchase.
  const referralCode = localStorage.getItem('referralCode');
  if (referralCode) {
    cartInfoData.referralCode = referralCode;
  }
  return _clientMutation({
    mutation: GraphQLTags.mutations.cartInfo,
    variables: cartInfoData,
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (!response.error) {
        localStorage.removeItem('referralCode');
      }
      return response;
    })
    .catch((error) => {
      return { error, result: null };
    });
};

const availablePointsQuery = () =>
  _clientQuery({
    query: GraphQLTags.queries.availablePointsQuery,
    variables: {},
  })
    .then((response) => response)
    .catch((error) => {
      return { error, result: null };
    });

const getPromoProductsForCart = () =>
  _clientQuery({
    query: GraphQLTags.queries.getPromoProductsForCart,
    variables: {},
    fetchPolicy: 'network-only',
  })
    .then((response) => response)
    .catch((error) => {
      return { error, result: null };
    });

const validateCouponsInCart = () =>
  _clientMutation({
    mutation: GraphQLTags.mutations.validateCouponsInCart,
    variables: {},
  })
    .then((response) => {
      const cart = response.data.validateCouponsInCart;
      return cart;
    })
    .catch((error) => ({ error, result: null }));

const validateProductsInCart = () =>
  _clientMutation({
    mutation: GraphQLTags.mutations.validateProductsInCart,
    variables: {},
  })
    .then((response) => {
      const cart = response.data.validateProductsInCart;
      return cart;
    })
    .catch((error) => ({ error, result: null }));

const coregOffers = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.coregOffers,
    variables: data,
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      const offers = response.data.coregOffers;
      return offers;
    })
    .catch((error) => ({ error, result: null }));

const sendAndStoreOffersSelected = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.sendAndStoreOffersSelected,
    variables: data,
  })
    .then((response) => {
      const user = response.data.sendAndStoreOffersSelected;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });

const getBreastPumpProducts = () =>
  _clientQuery({
    query: GraphQLTags.queries.getBreastPumpProducts,
    variables: {},
  })
    .then((response) => {
      const breastPumps = response.data.getBreastPumpProducts;
      return breastPumps;
    })
    .catch((error) => ({ error, result: null }));

const getBreastPumpProductsByInsurance = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.getBreastPumpProductsByInsurance,
    variables: data,
  })
    .then((response) => {
      const breastPumps = response.data.getBreastPumpProductsByInsurance;
      return breastPumps;
    })
    .catch((error) => ({ error, result: null }));

const addAddressToUser = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addAddressToUser,
    variables: data,
  })
    .then((response) => {
      const user = response.data.addAddressToUser;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });

const verifyInsuranceProviderAndAddDataToUser = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.verifyInsuranceProviderAndAddDataToUser,
    variables: data,
  })
    .then((response) => {
      const user = response.data.verifyInsuranceProviderAndAddDataToUser;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });

const getSignedUrlForInsurancePhotos = () =>
  _clientQuery({
    query: GraphQLTags.queries.getSignedUrlForInsurancePhotos,
    variables: {},
  })
    .then((response) => {
      const user = response.data.getSignedUrlForInsurancePhotos;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });

const addInsuranceDataAndCreatePurchase = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addInsuranceDataAndCreatePurchase,
    variables: data,
  })
    .then((response) => {
      const user = response.data.addInsuranceDataAndCreatePurchase;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });

const addOrRemoveShippingInsuranceForCart = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addOrRemoveShippingInsuranceForCart,
    variables: data,
  })
    .then((response) => {
      const res = response.data.addOrRemoveShippingInsuranceForCart;
      return res;
    })
    .catch((error) => {
      return { error, result: null };
    });

const addPartnerClickToUser = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addPartnerClickToUser,
    variables: data,
  })
    .then((response) => {
      const user = response.data.addPartnerClickToUser;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });

const getCompanyByCode = (companyCode) =>
  _clientQuery({
    query: GraphQLTags.queries.getCompanyByCode,
    variables: { companyCode },
  })
    .then((response) => {
      const company = response.data.getCompanyByCode;
      return company;
    })
    .catch((error) => ({ error, result: null }));

const validateCompanySignUpUrl = (signUpCode) =>
  _clientQuery({
    query: GraphQLTags.queries.validateCompanySignUpUrl,
    variables: { signUpCode },
  })
    .then((response) => {
      const company = response.data.validateCompanySignUpUrl;
      return company;
    })
    .catch((error) => ({ error, result: null }));

const clearCart = () =>
  client
    .mutate({
      mutation: GraphQLTags.mutations.clearCart,
      variables: {},
    })
    .then((response) => response)
    .catch((error) => {
      return { error, result: null };
    });

const getCorporateProducts = (companyCode) =>
  _clientQuery({
    query: GraphQLTags.queries.getCorporateProducts,
    variables: {
      companyCode,
    },
  })
    .then((response) => response.data.getCorporateProducts)
    .catch((error) => {
      return { error, result: null };
    });

const verifyCorporateAccount = (verificationData) =>
  client
    .mutate({
      mutation: GraphQLTags.mutations.verifyCorporateAccount,
      variables: verificationData,
    })
    .then((response) => {
      const user = response.data.verifyCorporateAccount;
      if (user && user.token) {
        storeToken(user.token);
      }
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });

const addEmailToMasterList = (data) =>
  client
    .query({
      query: GraphQLTags.queries.addEmailToMasterList,
      variables: data,
    })
    .then((response) => {
      const user = response.data.addEmailToMasterList;
      return user;
    })
    .catch((error) => ({ error, result: null }));

const addEmailToGiveawayList = (data) =>
  client
    .query({
      query: GraphQLTags.queries.addEmailToGiveawayList,
      variables: data,
    })
    .then((response) => {
      const user = response.data.addEmailToGiveawayList;
      return user;
    })
    .catch((error) => ({ error, result: null }));

const sendDueDateCalculatorKlaviyoEvents = (data) =>
  client
    .query({
      query: GraphQLTags.queries.sendDueDateCalculatorKlaviyoEvents,
      variables: data,
    })
    .then((response) => {
      const user = response.data.sendDueDateCalculatorKlaviyoEvents;
      return user;
    })
    .catch((error) => ({ error, result: null }));

const addEmailToBreastPumpMasterList = (data) =>
  client
    .query({
      query: GraphQLTags.queries.addEmailToBreastPumpMasterList,
      variables: data,
    })
    .then((response) => {
      const user = response.data.addEmailToBreastPumpMasterList;
      return user;
    })
    .catch((error) => ({ error, result: null }));

const getSubscriptionAddOnProducts = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.getSubscriptionAddOnProducts,
    variables: data,
    fetchPolicy: 'network-only',
  })
    .then((response) => {
      const subscriptionAddOnProducts =
        response.data.getSubscriptionAddOnProducts;
      return subscriptionAddOnProducts;
    })
    .catch((error) => ({ error, result: null }));

const getSubscriptionAddOnProductsForLandingPage = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.getSubscriptionAddOnProductsForLandingPage,
    variables: data,
    fetchPolicy: 'network-only',
  })
    .then((response) => {
      const subscriptionAddOnProducts =
        response.data.getSubscriptionAddOnProductsForLandingPage;
      return subscriptionAddOnProducts;
    })
    .catch((error) => ({ error, result: null }));

const getSubscriptionQuery = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.getSubscriptionQuery,
    variables: data,
  })
    .then((response) => {
      const subscription = response.data.getSubscription;
      return subscription;
    })
    .catch((error) => ({ error, result: null }));

const addOrRemoveProductToAddOns = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addOrRemoveProductToAddOns,
    variables: data,
  })
    .then((response) => {
      const subscription = response.data.addOrRemoveProductToAddOns;
      return subscription;
    })
    .catch((error) => ({ error, result: null }));

const createRegistry = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.createRegistry,
    variables: data,
  })
    .then((response) => {
      const registry = response.data.createRegistry;
      return registry;
    })
    .catch((error) => ({ error, result: null }));

const editRegistry = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.editRegistry,
    variables: data,
  })
    .then((response) => {
      const registry = response.data.editRegistry;
      return registry;
    })
    .catch((error) => ({ error, result: null }));

const getRegistryInfo = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.getRegistryInfo,
    variables: data,
  })
    .then((response) => {
      const registry = response.data.getRegistryInfo;
      return registry;
    })
    .catch((error) => ({ error, result: null }));

const getRegistryInfoForOwner = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.getRegistryInfoForOwner,
    variables: data,
  })
    .then((response) => {
      const registry = response.data.getRegistryInfoForOwner;
      return registry;
    })
    .catch((error) => ({ error, result: null }));

const searchForRegistries = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.searchForRegistries,
    variables: data,
  })
    .then((response) => {
      const registries = response.data.searchForRegistries;
      return registries;
    })
    .catch((error) => ({ error, result: null }));

const validateRegistryAddress = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.validateRegistryAddress,
    variables: data,
  })
    .then((response) => {
      const registries = response.data.validateRegistryAddress;
      return registries;
    })
    .catch((error) => ({ error, result: null }));

const getRegistryProduct = () =>
  _clientQuery({
    query: GraphQLTags.queries.getRegistryProduct,
    variables: {},
  })
    .then((response) => response.data.getRegistryProduct)
    .catch((error) => ({ error, result: null }));

const getProduct = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.productQuery,
    variables: data,
  })
    .then((response) => response.data.productInfo)
    .catch((error) => ({ error, result: null }));

const getProducts = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.getProductsQuery,
    variables: data,
  })
    .then((response) => response.data.getProducts)
    .catch((error) => ({ error, result: null }));

const plansForProviderQuery = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.plansForProviderQuery,
    variables: data,
  })
    .then((response) => response.data.plansForProvider)
    .catch((error) => ({ error, result: null }));

const getActiveSaleEvents = () =>
  _clientQuery({
    query: GraphQLTags.queries.getActiveSaleEvents
  })
    .then((response) => response.data.getActiveSaleEvents)
    .catch((error) => ({ error, result: null }));

const addAddressToRegistryCart = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addAddressToRegistryCart,
    variables: data,
  })
    .then((response) => {
      const cart = response.data.addAddressToRegistryCart;
      return cart;
    })
    .catch((error) => ({ error, result: null }));

const addOrRemoveShippingInsuranceForSubscription = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addOrRemoveShippingInsuranceForSubscription,
    variables: data,
  })
    .then((response) => {
      const cart = response.data.addOrRemoveShippingInsuranceForSubscription;
      return cart;
    })
    .catch((error) => ({ error, result: null }));

const storeAndSendSMSOptIn = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.storeAndSendSMSOptIn,
    variables: data,
  })
    .then((response) => {
      const user = response.data.storeAndSendSMSOptIn;
      return user;
    })
    .catch((error) => ({ error, result: null }));

const getPreCheckoutPromoProduct = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.getPreCheckoutPromoProduct,
    variables: data,
  })
    .then((response) => response.data.getPreCheckoutPromoProduct)
    .catch((error) => ({ error, result: null }));

const orderInfoQuery = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.orderInfoQuery,
    variables: data,
  })
    .then((response) => response.data.orderInfo)
    .catch((error) => ({ error, result: null }));

const checkIfSubscriptionExists = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.checkIfSubscriptionExists,
    variables: data,
  })
    .then((response) => response.data.checkIfSubscriptionExists)
    .catch((error) => ({ error, result: null }));

const getReferralCodeForLoggedInUser = () =>
  _clientMutation({
    mutation: GraphQLTags.mutations.getReferralCodeForLoggedInUser,
  })
    .then((response) => response.data.getReferralCodeForLoggedInUser)
    .catch((error) => ({ error, result: null }));

const getReferralCodeForNewOrReturningUser = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.getReferralCodeForNewOrReturningUser,
    variables: data,
  })
    .then((response) => response.data.getReferralCodeForNewOrReturningUser)
    .catch((error) => ({ error, result: null }));

const getInsuranceCoveredPumps = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.getInsuranceCoveredPumps,
    variables: data,
  })
    .then((response) => {
      return response.data.getInsuranceCoveredPumps;
    })
    .catch((error) => ({ error, result: null }));

const allBabyNames = (data) =>
  _clientQuery({
    query: GraphQLTags.queries.allBabyNames,
    variables: data,
  })
    .then((response) => {
      const babyNames = response.data.allBabyNames;
      return babyNames;
    })
    .catch((error) => ({ error, result: null }));

const addCoregCheckoutData = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.addCoregCheckoutData,
    variables: data,
  })
    .then((response) => {
      const user = response.data.addCoregCheckoutData;
      BBAnalytics.newUserEvent(user, 'Checkout');
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });

const purchaseCoregCart = (data) =>
  _clientMutation({
    mutation: GraphQLTags.mutations.purchaseCoregCart,
    variables: data,
  })
    .then((response) => {
      const user = response.data.purchaseCoregCart;
      return user;
    })
    .catch((error) => {
      return { error, result: null };
    });



export default {
  client,
  confirmUserPassword,
  signInUser,
  signUpUser,
  refreshUserToken,
  signOutUser,
  getEmptyUser,
  clearClientStore,
  userInfoQuery,
  cartItemCountQuery,
  cartInfo,
  availablePointsQuery,
  addDiscountToCart,
  removeDiscountFromCart,
  editAccountDetails,
  editShippingAddress,
  editPaymentMethod,
  editBabyDetails,
  sendForgotPasswordLink,
  resetPassword,
  skipNextMonth,
  sendCorporateGiftingContactEmail,
  sendEmailToHelloBitsyBoxesWithMessage,
  addReview,
  editReview,
  deleteReview,
  addAllShippingData,
  addSubscriptionDetails,
  reactivateSubscription,
  extendSubscription,
  addItemToCart,
  swapGWPItem,
  purchaseCart,
  removeItemFromCart,
  changeQuantityOfItemInCart,
  removeSubscriptionFromCart,
  addItemToTempCart,
  addGuestCheckoutData,
  updatePointsAppliedStatus,
  getTempCart,
  getTempCartAsString,
  getCosts,
  unskipNextMonth,
  cancelSubscription,
  updateRenewalCouponAsUser,
  getPromoProductsForCart,
  validateCouponsInCart,
  validateProductsInCart,
  sendAndStoreOffersSelected,
  coregOffers,
  getBreastPumpProducts,
  getBreastPumpProductsByInsurance,
  addAddressToUser,
  addInsuranceDataAndCreatePurchase,
  addPartnerClickToUser,
  getCompanyByCode,
  validateCompanySignUpUrl,
  clearCart,
  getCorporateProducts,
  verifyCorporateAccount,
  verifyInsuranceProviderAndAddDataToUser,
  addEmailToMasterList,
  addEmailToGiveawayList,
  sendDueDateCalculatorKlaviyoEvents,
  addEmailToBreastPumpMasterList,
  getSubscriptionAddOnProducts,
  getSubscriptionQuery,
  addOrRemoveProductToAddOns,
  createRegistry,
  editRegistry,
  getRegistryInfo,
  getRegistryInfoForOwner,
  searchForRegistries,
  validateRegistryAddress,
  getRegistryProduct,
  getProduct,
  getProducts,
  plansForProviderQuery,
  addOrRemoveShippingInsuranceForCart,
  addAddressToRegistryCart,
  addOrRemoveShippingInsuranceForSubscription,
  storeAndSendSMSOptIn,
  checkIfShouldHandleAsInfo,
  getPreCheckoutPromoProduct,
  getSubscriptionAddOnProductsForLandingPage,
  orderInfoQuery,
  checkIfSubscriptionExists,
  getReferralCodeForLoggedInUser,
  getReferralCodeForNewOrReturningUser,
  storeToken,
  getInsuranceCoveredPumps,
  allBabyNames,
  addCoregCheckoutData,
  purchaseCoregCart,
  getSignedUrlForInsurancePhotos,
  getActiveSaleEvents,
};
