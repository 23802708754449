import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Row, Col, Image } from 'react-bootstrap';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faXmark,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import WebpImage from '../webp-image';
import CtaButton from '../cta-button';

import Content from '../../constants/Content';
import Images from '../../images';

const modalContent = {
  updateCCForm: {
    title: 'Update Credit Card',
  },
  checkoutSubscriptionTerms: {
    body: (
      <div>
        <div className='displayInlineText'>
          By clicking on &apos;Place Order&apos; you confirm that you accept our{' '}
        </div>
        <Link
          to='/terms-of-service'
          target='_blank'
          className='displayInlineText mainLinks'
        >
          Terms of Service
        </Link>
        <div className='displayInlineText'>
          {' '}
          & that your subscription will automatically renew based on the
          subscription billing period. Your credit card will automatically be
          charged the subscription fee until you cancel your subscription. You
          can cancel your subscription anytime by contacting us at{' '}
          {Content.names.helpEmail}
        </div>
      </div>
    ),
    title: 'Monthly Billing Terms',
  },
  thankYouSubscriptionTerms: {
    body: (
      <div>
        Your subscription will be renewed monthly and you will be charged the
        monthly subscription fee until you cancel. This monthly charge may
        change if you change your membership, or our prices or shipping change
        (with notice to you). You can cancel anytime by visiting your account
        page on our website. Cancellations must be placed at least one full
        calendar day before your next box ships to avoid being charged.
      </div>
    ),
    title: 'Monthly Billing Terms',
  },
  worstCaseScenarioCheckout: {
    title: 'Oh no!',
    body:
      "Something seems wrong here, but we're on it!  Our customer service team will be in contact with you within 24 hours to confirm your order status and details.",
    mainButtonText: 'Confirm',
  },
  subscribedFromFooter: {
    body: `Thanks for subscribing to ${Content.names.singleName}!  Your email has been added.`,
    title: 'Yay!',
  },
  resetPasswordRequest: {
    body:
      'Reset request received. A link to reset your password will be sent to your email if an account exists.',
    title: 'Yay!',
  },
  resetPasswordConfirmed: {
    body: 'Your password was updated.',
    title: 'Yay!',
  },
  checkSkipMonth: {
    body: 'Are you sure you want to skip and miss out on your next box?',
    title: 'Skip Month',
    secondaryButtonText: 'Go back without skipping',
    mainButtonText: 'Continue with skip',
    mainButtonStyle: 'link',
    secondaryButtonStyle: 'info',
  },
  checkUnskipMonth: {
    body: 'Are you sure you want to unskip this month?',
    title: 'Unskip Month',
    mainButtonText: 'Confirm',
  },
  unsubscribeBundledSub: {
    title: 'Are you sure you want to cancel?',
    body: (
      <div>
        <WebpImage
          imageName='pastBox5'
          className='past-box-img'
          width='200px'
        />
        <p>
          If you would like to cancel your prepaid subscription please{' '}
          <Link to='/contact'>contact us</Link>
        </p>
      </div>
    ),
    mainButtonText: 'OK',
  },
  unsubscribe: {
    title: 'Are you sure you want to cancel?',
    body: (
      <WebpImage imageName='pastBox5' className='past-box-img' width='200px' />
    ),
    secondaryButtonText: 'GO BACK',
    mainButtonText: 'Continue',
    mainButtonStyle: 'link',
    secondaryButtonStyle: 'info',
  },
  unsubscribeSurvey: {
    title: 'Why are you cancelling?',
  },
  couponUnsubscribe: {
    title: 'Stay with us!',
    body: (
      <div>
        <WebpImage
          imageName='pastBox5'
          className='past-box-img'
          width='200px'
        />
        <p>and get 20% off your next 3 boxes</p>
      </div>
    ),
    secondaryButtonText: 'Get 20% Off',
    mainButtonText: 'No thanks, Please Cancel',
    mainButtonStyle: 'link',
    secondaryButtonStyle: 'info',
  },
  makePumpPayment: {
    title: 'Make payment',
    body: "You'll receive an email notification once your payment is submitted",
    mainButtonText: '< Go Back',
    mainButtonStyle: 'link',
  },
  breastPumpAddOnEdit: {
    title: '',
    body: '',
    secondaryButtonText: 'UPDATE SIZE',
    mainButtonText: 'Decline this offer',
    mainButtonStyle: 'link',
    secondaryButtonStyle: 'info',
    ctaButton: true,
    mainButtonClassName: 'breastPumpConfirmMain',
    secondaryButtonClassName: 'breastPumpConfirmSecondary',
  },
  breastPumpAddOn: {
    title: 'Select add-ons',
    body: '',
    secondaryButtonText: 'Confirm',
    mainButtonText: 'Skip Add-Ons >',
    mainButtonStyle: 'link',
    secondaryButtonStyle: 'info',
    ctaButton: true,
    mainButtonClassName: 'breastPumpConfirmMain',
    secondaryButtonClassName: 'breastPumpConfirmSecondary',
  },
  breastPumpConfirm: {
    title: 'Confirm your selection',
    body: '',
    secondaryButtonText: 'Submit',
    mainButtonText: '< Go Back',
    mainButtonStyle: 'link',
    secondaryButtonStyle: 'info',
    ctaButton: true,
    mainButtonClassName: 'breastPumpConfirmMain',
    secondaryButtonClassName: 'breastPumpConfirmSecondary',
  },
  breastPumpPurchase: {
    title: 'Confirm your selection',
    body: '',
    secondaryButtonText: 'Continue To Payment',
    mainButtonText: '< Go Back',
    mainButtonStyle: 'link',
    ctaButton: true,
    mainButtonClassName: 'breastPumpConfirmMain',
  },
  viewProductDetails: {
    body: '',
  },
  viewSizeChart: {
    title: 'Size Chart',
    body: '',
  },
  finalUnsubscribe: {
    title: 'Confirm Your Cancellation',
    body: (
      <div>
        <WebpImage
          imageName='pastBox5'
          className='past-box-img'
          width='200px'
        />
      </div>
    ),
    secondaryButtonText: 'Cancel Now',
    mainButtonText: 'Go Back',
    mainButtonStyle: 'link',
    secondaryButtonStyle: 'info',
  },
  unsubscribeVitamin: {
    title: 'Confirm Your Cancellation',
    body: (
      <div>
        <Image
          src={Images.prenatalVitamins.bottleImage.src}
          alt='Prenatal Front Bottle'
          className='past-box-img'
          width='200px'
        />
      </div>
    ),
    secondaryButtonText: 'Cancel Now',
    mainButtonText: 'Go Back',
    mainButtonStyle: 'link',
    secondaryButtonStyle: 'info',
  },
  extendLandingAlmostThere: {
    body: (
      <div className='extend-landing-body'>
        <p>
          Please confirm that you are ready to renew with a 6 month
          subscription.
        </p>
        <br />
        <p>You are only one click away from getting your free box!</p>
      </div>
    ),
    title: 'Almost There!',
    mainButtonText: 'Go Back',
    mainButtonStyle: 'link',
    secondaryButtonText: 'Confirm',
    secondaryButtonStyle: 'info',
  },
  extendLandingThankYou: {
    body: (
      <div>
        <p>
          Welcome Back! Your subscription has been extended for 6 months and you
          will receive your FREE box next month!
        </p>
        <br />
        <p>
          Enjoy site-wide discounts & FREE shipping when you add pregnancy &
          baby-safe items to your box!
        </p>
        <p>
          Once baby is born, you will receive monthly Bitsy Boxes filled with
          safe & healthy products for mom & baby!
        </p>
      </div>
    ),
    title: (
      <div className='extend-landing-title'>
        <FontAwesomeIcon
          icon={faCheckCircle}
          size='2x'
          className='extend-landing-checkmark'
        />
        <p className='postPurchaseSuccessTitle'>Thank You!</p>
      </div>
    ),
    mainButtonText: 'Go Back',
    mainButtonStyle: 'link',
    secondaryButtonText: 'Shop Now',
    secondaryButtonStyle: 'info',
  },
  earlyCancelCCUpdateSuccess: {
    body: 'Your credit card has been updated.',
    title: 'Yay!',
    mainButtonText: 'Return to Payment',
  },
  success: {
    body: 'Your changes have been made.',
    title: 'Yay!',
    mainButtonText: 'OK',
  },
  postPurchasePromoSuccess: {
    body: (
      <div className='postPurchaseSuccesSubTitleDiv'>
        <span>WE WILL NOTIFY YOU WHEN YOUR PACKAGE IS ON ITS WAY</span>
      </div>
    ),
    title: (
      <div>
        <hr className='postPurchaseSuccesHr' />
        <p className='postPurchaseSuccessTitle'>Thank you for your order!</p>
      </div>
    ),
    mainButtonText: 'CLOSE',
  },
  sponsorSuccess: {
    body: 'Your selected sponsors will reach out to you shortly.',
    title: 'Thank you!',
    mainButtonText: 'OK',
  },
  unsubSuccess: {
    body: 'Your subscription has been successfully cancelled',
    title: 'Subscription Cancelled',
    mainButtonText: 'OK',
  },
  couponSuccess: {
    body: 'The coupon has been applied to your subscription.',
    title: 'Yay!',
    mainButtonText: 'OK',
  },
  corporateGiftingRequestSent: {
    body:
      'Thank you! Your request has been sent. We will be in contact with you shortly!',
    title: 'Request Sent',
  },
  contactSuccess: {
    body:
      'Thank you! Your message has been sent. We will be in contact with you shortly!',
    title: 'Message Sent!',
    mainButtonText: 'OK',
  },
  reviewSuccess: {
    body:
      'Thanks for reviewing!  Once your review has been approved, it will appear on this product page!  It will also be added to your My Account page, where you can edit or delete your review at any time.',
    title: 'Review Successfully Submitted',
  },
  deleteReview: {
    body: 'Are you sure you want to delete this review?',
    title: 'Delete Review',
    mainButtonText: 'Confirm',
  },
  deleteReviewSuccess: {
    body: 'Your review was deleted! It has been updated across the website.',
    title: 'Successful Deletion',
  },
  reviewEditSuccess: {
    body:
      'Your edit was successful! Once your review is approved it will be updated across the website.',
    title: 'Successful Edit',
  },
  cartSuccess: {
    body: 'This item has been added to your cart!',
    title: 'Item In Cart',
    mainButtonText: 'Go To Cart',
    secondaryButtonText: 'Continue Shopping',
  },
  aboveStock: {
    body: '',
    title: 'You ordered more than we have!',
    mainButtonText: 'Go To Cart',
    secondaryButtonText: 'Continue Shopping',
  },
  alreadyHasSubscription: {
    body:
      'Oops! Looks like you already have a subscription in your cart! We can only process one subscription per purchase. In order to add a new subscription, remove the current subscription from your cart!',
    title: 'One Subscription Per Purchase',
    mainButtonText: 'Go To Cart',
    secondaryButtonText: 'Cancel',
  },
  deleteProductFromCart: {
    body: '',
    title: 'Delete Product From Cart',
    mainButtonText: 'Delete',
    secondaryButtonText: 'Cancel',
  },
  aboveStockInCartReview: {
    body: '',
    title: "You've reached our limit",
    mainButtonText: 'Close',
  },
  deleteSubFromCart: {
    body: 'Are you sure you want to delete the subscription from your cart?',
    title: 'Delete Subscription from cart',
    mainButtonText: 'Delete',
    secondaryButtonText: 'Cancel',
  },
  error: {
    body: '',
    title: 'Something went wrong',
    mainButtonText: 'Close',
  },
  addOnProductError: {
    title: 'Uh Oh!',
    body: (
      <p>
        Looks like you do not have any subscriptions that are eligible for
        Add-Ons. If you have any questions, please{' '}
        <Link to='/contact'>contact us</Link>.
      </p>
    ),
    mainButtonText: 'Close',
  },
  addOnThankYou: {
    title: 'Thank You!',
    body:
      'You will be billed for these products on your next scheduled payment. If you have any questions call our Customer Experience Mom Squad at 888-913-7879.',
    mainButtonText: 'DONE',
  },
  recurringAddOnThankYou: {
    title: 'Thank You!',
    body:
      'You will be billed for these products starting on your next scheduled payment and each following month. If you have any questions call our Customer Experience Mom Squad at 888-913-7879.',
    mainButtonText: 'DONE',
  },
  tooEarly: {
    title: "You're Early!",
    body: (
      <p>
        Your add-on window opens soon. Check back later and{' '}
        <a
          className='mainLinks'
          href='https://bumpboxes.attn.tv/p/YUV/landing-page'
        >
          sign up
        </a>{' '}
        here for an early alert!
      </p>
    ),
    mainButtonText: 'Close',
  },
  mustHaveSub: {
    title: 'GET ACCESS',
    body: (
      <p>
        You found a Mom Squad perk! Subscribe today to save on pregnancy
        essentials (psst...these all ship free, too).{' '}
        <Link to='/subscribe/select' className='mainLinks'>
          Join here →
        </Link>
        .
      </p>
    ),
    mainButtonText: 'Close',
  },
  mustHaveActiveSub: {
    title: 'GET ACCESS',
    body: (
      <p>
        Reactivate your subscription today & save on pregnancy essentials
        (psst...these all ship free, too).{' '}
        <Link to='/subscribe/select' className='mainLinks'>
          Start here →
        </Link>
        .
      </p>
    ),
    mainButtonText: 'Close',
  },
  addOnToNextBoxThankYou: {
    title: 'Thank You!',
    body:
      'You will be billed for these products on your next scheduled payment. If you have any questions call our Customer Experience Mom Squad at 888-913-7879.',
    mainButtonText: 'OK',
  },
  removeShippingInsurance: {
    body:
      'Are you sure you want to remove shipping protection for all future subscription purchases?',
    title: 'Remove Shipping Protection',
    mainButtonText: 'Confirm',
  },
  addShippingInsurance: {
    body:
      'Are you sure you want to add shipping protection for future subscription purchases for $1.99 each month?',
    title: 'Add Shipping Protection',
    mainButtonText: 'Confirm',
  },
  smsOptInDetails: {
    body: (
      <div className='smsOptInDetailsDiv'>
        By checking this box, I agree to receive recurring automated marketing
        text msgs (e.g. cart reminders) to the mobile number used at opt-in from
        Bump Boxes on 63846. Consent is not a condition of purchase. Msg
        frequency may vary. Msg & data rates may apply. Reply HELP for help and
        STOP to cancel. I also acknowledge and agree to the{' '}
        <Link to='/terms-of-service' target='_blank' className='termsLink'>
          Terms & Conditions
        </Link>{' '}
        and{' '}
        <Link to='/privacy-policy' target='_blank' className='termsLink'>
          Privacy Policy
        </Link>
        .
      </div>
    ),
    title: 'SMS Opt-In',
    isTitleH2: true,
  },
  assignmentOfBenefits: {
    body: (
      <div className='smsOptInDetailsDiv'>
        <p>
          Before we ship your product(s), we need you to authorize Bump Health
          Inc, parent company to Bump Boxes, to provide products or services to
          you.
        </p>
        <p>
          By agreeing below, you authorize Bump Health Inc to submit claims to
          your insurance company on your behalf and request on your behalf all
          insurance information, prescriptions and benefits for products
          provided as well as to release your medical records to any person,
          organization, company and/or agency which may be involved in providing
          your care.
        </p>
        <p>
          You will be offered a standard electric breast pump that is fully
          covered by your insurance. If you chose an upgraded breast pump, you
          will be responsible for the price difference. In choosing an upgraded
          breast pump, you give up any right to dispute the remaining balance
          due, either with the provider Bump Health or to the primary insurer of
          record.
        </p>
        <p>
          <b>
            You also acknowledge that you have not ordered another breast pump
            through your insurance for this pregnancy.
          </b>
        </p>
      </div>
    ),
    title: 'Assignment of Benefits',
    isTitleH2: true,
  },
  freeShippingDetails: {
    body: (
      <div className='smsOptInDetailsDiv'>
        Monthly billing subscriptions renew automatically to keep the fun coming
        - cancel renewal anytime once your term has been met! Charges may apply
        for early cancellation. $14.99 will be applied for international
        shipping outside the US (Taxes & Duties Included). $9.99 will be applied
        for HI and AK.
      </div>
    ),
    title: 'Free U.S. Shipping & Returns',
    isTitleH2: true,
  },
  preCheckoutPromoPopUp: {
    title: 'ONE TIME OFFER',
  },
  preCheckoutPromoPopUpPremiumUpgrade: {
    title: (
      <p>
        Step 2 of 3: <br /> Add Premium Upgrade
      </p>
    ),
  },
  becomeASubscriber: {
    body: 'Become a subscriber and save money each time you shop!',
    title: 'You’re missing out!',
    mainButtonText: 'Subscribe Now!',
  },
  extensionConfirmation: {
    body:
      'Come back and get your first healthy baby box for FREE when you re-subscribe.',
    title: 'Wait! ONE TIME OFFER!',
    secondaryButtonText: 'YES! GET BABY BOXES!',
    mainButtonText: 'NO THANKS',
    mainButtonStyle: 'link',
    secondaryButtonStyle: 'info',
  },
  coregSelectAllOffers: {
    title: 'Confirm Auto Selection',
    body: 'Please confirm that you agree to select all of our offers at once.',
    mainButtonText: 'CONFIRM',
  },
  referralTermsAndConditions: {
    title: 'Terms & Conditions',
    body: (
      <>
        <p>
          24 hours after a successful purchase of a 6, 9 or 12 month plan by the
          friend referred using the referral discount code, advocate will
          receive an account credit good for one free month of their current
          subscription. Advocate must have current, active monthly Bump Boxes
          subscription in good financial standing. Bundled subscribers will
          receive an extra box per successful referral. Referral sales must be
          made on
          <Link to='/' target='_blank' className='displayInlineText termsLink'>
            bumpboxes.com
          </Link>
          .
        </p>
        <p>
          Any abuse of this offer, as determined by Bump Boxes, may result in
          the rescission of the referring customer’s referral credit and the
          referred person’s promo code as well as both parties’ inability to
          participate in this or future promotions. Referral credit cannot be
          applied to previous purchases, and is not redeemable for cash. This
          referral program is subject to modification or termination at any time
          without notice in our sole discretion.
        </p>
      </>
    ),
  },
  // Just an empty title to have the close button on the right side
  referralShare: {
    title: '               ',
  },
  referralShareCopyLink: {
    title: 'Share Via Link',
  },
  confirmPassword: {
    title: 'Confirm Password',
    mainButtonText: 'Submit',
    mainButtonStyle: 'link',
  },
  cancelSubValue: {
    title: "With Bump Boxes, you've saved:",
    mainButtonText: 'Continue Cancellation',
    mainButtonStyle: 'link',
    secondaryButtonText: "Don't Cancel",
    secondaryButtonStyle: 'info',
  },
  cancelStepOne: {
    title: 'Don’t Miss Out!',
    body: (
      <>
        <p>When you cancel, you miss out on:</p>
        <ul>
          <li>
            <strong>5 full-sized items</strong> for you and baby
          </li>
          <li>Exclusive Add-Ons at your VIP discounts</li>
          <li>Free offers and giveaways</li>
        </ul>
      </>
    ),
    mainButtonText: 'Continue Cancellation',
    mainButtonStyle: 'link',
    secondaryButtonText: 'Never Mind',
    secondaryButtonStyle: 'info',
  },
  unsubscribeSkip: {
    title: ['Why Cancel?', 'Just Skip A Month.'],
    body: 'You won’t be charged for the skipped month.',
    mainButtonText: 'Continue Cancellation',
    mainButtonStyle: 'link',
    secondaryButtonText: 'Skip A Month',
    secondaryButtonStyle: 'info',
  },
  unsubscribeSkipFinancial: {
    title: 'Would you like to skip a month instead?',
    body: 'You won’t be charged for the skipped month.',
    mainButtonText: 'Continue Cancellation',
    mainButtonStyle: 'link',
    secondaryButtonText: 'Skip A Month',
    secondaryButtonStyle: 'info',
  },
  subscriptionSkipSuccess: {
    title: 'Thanks!',
    body: (
      <>
        <p>You&apos;ve successfully skipped your next box.</p>
        <p>You won&apos;t be charged for next month&apos;s box.</p>
      </>
    ),
    mainButtonText: 'OK',
    mainButtonStyle: 'info',
  },
  subscriptionExtensionSuccess: {
    title: 'Yay!',
    body: (
      <>
        <p>Your subscription has been extended.</p>
      </>
    ),
    mainButtonText: 'OK',
    mainButtonStyle: 'info',
  },
  contactCustomerService: {
    title: 'Oh no!',
    body: (
      <>
        <p>
          We’d love to make this right and get you products that you will use!
        </p>
        <p>
          Contact our Mom Squad and you can return your unused, unopened
          item(s), and we’ll give you store credit so you can pick out something
          you’ll love!
        </p>
      </>
    ),
    mainButtonText: 'Continue Cancellation',
    mainButtonStyle: 'link',
    secondaryButtonText: 'Contact Support',
    secondaryButtonStyle: 'info',
  },
  cancellationOffer: {
    title: 'Your first Bitsy Box is FREE!',
    body: (
      <>
        <Image
          src={Images.cancellationOffer.src}
          alt={Images.cancellationOffer.alt}
          className='past-box-img'
          width='200px'
        />
        <p>Stay with us for 6 months and your next box is FREE!</p>
        <p>
          We’ll deliver 5 full-size, age appropriate & baby safe items to your
          door every month.
        </p>
      </>
    ),
    mainButtonText: 'Continue Cancellation',
    mainButtonStyle: 'link',
    secondaryButtonText: 'Get My Offer',
    secondaryButtonStyle: 'info',
  },
  earlyCancellationAlert: {
    title: 'Are you sure you want to cancel?',
    body: (
      <>
        <p>
          Cancelling your plan today means you’ll be charged an early
          cancellation fee of <strong>$25</strong>.{' '}
          <Link to='/terms-of-service' target='_blank' className='termsLink'>
            Learn more
          </Link>
        </p>
        <p>
          As part of the original subscription agreement, this fee will apply if
          you cancel now and end your commitment early.
        </p>
        <p>
          Please reach out to our{' '}
          <Link to='/contact' target='_blank' className='termsLink'>
            Mom Squad
          </Link>{' '}
          if you have any questions or concerns.
        </p>
      </>
    ),
    mainButtonText: 'Continue Cancellation',
    mainButtonStyle: 'link',
    secondaryButtonText: 'Never Mind',
    secondaryButtonStyle: 'info',
  },
  earlyCancellationPayment: {
    title: 'Please confirm your payment method',
    body: (
      <>
        <p>
          By clicking the PAY button below, you’ll be charged $25 on the credit
          card linked to your account.
        </p>
        <p>
          Please reach out to our{' '}
          <Link to='/contact' target='_blank' className='termsLink'>
            Mom Squad
          </Link>{' '}
          if you have any questions or concerns.
        </p>
      </>
    ),
    mainButtonText: 'Update My Credit Card',
    mainButtonStyle: 'link',
    secondaryButtonText: (
      <>
        <FontAwesomeIcon icon={faLock} className='lockIcon' />
        Pay $25
      </>
    ),
    secondaryButtonStyle: 'primary place-credit-card-order',
  },
  lossUnsubscribe: {
    title: '',
    body: (
      <>
        <p>
          We are so sorry for your loss. Please click the CANCEL button to
          ensure your subscription is cancelled.
        </p>
        <p>If you need anything, our Mom Squad is here to talk.</p>
      </>
    ),
    mainButtonText: 'Cancel',
    mainButtonStyle: 'info',
  },
  estimatedSavings: {
    title: `With ${Content.names.fancyNamePlural}, you’ve saved:`,
  },
  estimatedSavingsGuest: {
    title: `Save time and money with ${Content.names.fancyNamePlural}!`,
  },
};

const renderMultiStringTitle = (title) => {
  return (
    <h1 className='mutipleLine'>
      {title.map((line, i) => (
        <React.Fragment key={line}>
          {line}
          {i < title.length - 1 ? <br /> : null}
        </React.Fragment>
      ))}
    </h1>
  );
};

const bodyAndExitModal = function bodyAndExitModal(props) {
  const content = modalContent[props.content];
  return (
    <Modal
      className='modal center-align'
      show
      onHide={props.onExit}
      backdrop='static'
      id={props.id}
      scrollable={props.scrollable || false}
    >
      <Modal.Header
        className={`modal-title ${props.modalHeaderClass}`}
        onHide={props.onExit}
      >
        {content.isTitleH2 ? (
          <h2 className={props.h2ClassName}>{content.title}</h2>
        ) : (
          <h1>{content.title}</h1>
        )}
        <Button
          autoFocus
          onClick={props.onExit}
          variant='incognito'
          className='modal-title-item float-right link'
          aria-label='Close window'
        >
          <FontAwesomeIcon icon={faXmark} className='closeIcon' size='lg' />
        </Button>
      </Modal.Header>
      <Modal.Body>{content.body}</Modal.Body>
    </Modal>
  );
};

const bodyWithDataAndExitModal = function bodyWithDataAndExitModal(props) {
  const content = modalContent[props.content];
  return (
    <Modal
      className='modal center-align'
      show
      onHide={props.onExit}
      backdrop='static'
    >
      <Modal.Header className='modal-title' onHide={props.onExit}>
        <h1>{content.title}</h1>
        <Button
          autoFocus
          onClick={props.onExit}
          variant='incognito'
          className='modal-title-item float-right link'
          aria-label='Close window'
        >
          <FontAwesomeIcon icon={faXmark} className='closeIcon' size='lg' />
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div>{content.body}</div>
        <div>{props.data.additionalBody}</div>
      </Modal.Body>
    </Modal>
  );
};

const bodyAndExitModalWithButton = function bodyAndExitModalWithButton(props) {
  const content = modalContent[props.content];
  return (
    <Modal
      className={`modal center-align ${props.className}`}
      id={props.id || ''}
      show
      onHide={props.onExit}
      backdrop='static'
    >
      <Modal.Header className='modal-title' onHide={props.onExit}>
        <h1>{content.title}</h1>
        <Button
          autoFocus
          onClick={props.onExit}
          variant='incognito'
          className='modal-title-item float-right link'
          aria-label='Close window'
        >
          <FontAwesomeIcon icon={faXmark} className='closeIcon' size='lg' />
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className='contentBody'>{content.body}</div>
        <div>{props.data.additionalBody ? props.data.additionalBody : ''}</div>
        <div className='textCenter mt-4'>
          <Button
            variant={
              content.mainButtonStyle ? content.mainButtonStyle : 'primary'
            }
            onClick={props.onClickMainButton}
            className='mainButton'
          >
            {content.mainButtonText}
          </Button>
        </div>
        <div className='helperText'>
          {props.data.helperText ? props.data.helperText : ''}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const bodyAndExitModalWithTwoButtons = function bodyAndExitModalWithTwoButtons(
  props,
) {
  const content = modalContent[props.content];
  return (
    <Modal
      id={props.id || 'bodyAndExitModalWithTwoButtonsModal'}
      className='modal center-align'
      backdrop='static'
      show
      onHide={props.onExit}
    >
      <Modal.Header className='modal-title' onHide={props.onExit}>
        {content.title instanceof Array ? (
          renderMultiStringTitle(content.title)
        ) : (
          <h1>{content.title}</h1>
        )}
        <Button
          autoFocus
          onClick={props.onExit}
          variant='incognito'
          className='modal-title-item float-right link'
          aria-label='Close window'
        >
          <FontAwesomeIcon icon={faXmark} className='closeIcon' size='lg' />
        </Button>
      </Modal.Header>

      <Modal.Body
        style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}
      >
        <div>{content.body}</div>
        <div>{props.data.additionalBody ? props.data.additionalBody : ''}</div>
        <Row className='textCenter'>
          <Col className='mt-4'>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 8, offset: 2 }}
              >
                {!content.ctaButton ? (
                  <Button
                    className={
                      !content.secondaryButtonClassName
                        ? 'two-button-modal-button'
                        : `two-button-modal-button ${content.secondaryButtonClassName}`
                    }
                    variant={
                      content.secondaryButtonStyle
                        ? content.secondaryButtonStyle
                        : 'default'
                    }
                    onClick={props.onClickSecondaryButton}
                    disabled={props.isDisabled}
                  >
                    {content.secondaryButtonText}
                  </Button>
                ) : (
                  <CtaButton
                    className='two-button-modal-button'
                    buttonText={content.secondaryButtonText}
                    onClick={props.onClickSecondaryButton}
                    disabled={props.isDisabled}
                    largerWidth
                  />
                )}
              </Col>
              <Col
                xs={12}
                sm={12}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 8, offset: 2 }}
                className='mt-2'
              >
                <Button
                  className={
                    !content.mainButtonClassName
                      ? 'two-button-modal-button'
                      : `two-button-modal-button ${content.mainButtonClassName}`
                  }
                  variant={
                    content.mainButtonStyle
                      ? content.mainButtonStyle
                      : 'primary'
                  }
                  style={{ width: '100%' }}
                  onClick={props.onClickMainButton}
                  disabled={props.isDisabled}
                >
                  {content.mainButtonText}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const modalWithComponent = function modalWithComponent(props) {
  const content = props.content ? modalContent[props.content] : null;
  let titleToUse;
  if (content && content.title) {
    titleToUse = content.title;
  }
  if (props.modalTitle) {
    titleToUse = props.modalTitle;
  }
  return (
    <Modal
      className={`center-align ${props.className || ''}`}
      id={props.id || ''}
      show
      onHide={props.onExit}
      backdrop='static'
      scrollable={props.scrollable || false}
    >
      <Modal.Header className='modal-title'>
        {titleToUse ? <h1>{titleToUse}</h1> : null}
        {!props.hideExit ? (
          <Button
            autoFocus
            onClick={props.onExit}
            variant='incognito'
            className='modal-title-item float-right link'
            aria-label='Close window'
          >
            <FontAwesomeIcon icon={faXmark} className='closeIcon' size='lg' />
          </Button>
        ) : null}
        {props.modalHeaderComponent ? props.modalHeaderComponent : null}
      </Modal.Header>
      <Modal.Body className='withComponentBody'>
        {props.modalComponent}
      </Modal.Body>
    </Modal>
  );
};

const componentModalWithTwoButtons = function componentModalWithTwoButtons(
  props,
) {
  const content = props.content ? modalContent[props.content] : null;
  return (
    <Modal
      className={`center-align ${props.className || ''}`}
      id={props.id || ''}
      show
      onHide={props.onExit}
      backdrop='static'
      scrollable={props.scrollable || false}
      style={{
        height: '300px',
        overflowY: 'auto',
      }}
    >
      <Modal.Header className='modal-title'>
        {content && content.title ? <h1>{content.title}</h1> : null}
        {!props.hideExit ? (
          <Button
            autoFocus
            onClick={props.onExit}
            variant='incognito'
            className='modal-title-item float-right link'
            aria-label='Close window'
          >
            <FontAwesomeIcon icon={faXmark} className='closeIcon' size='lg' />
          </Button>
        ) : null}
        {props.modalHeaderComponent ? props.modalHeaderComponent : null}
      </Modal.Header>
      <Modal.Body className='withComponentBody'>
        {props.modalComponent}
        <Row className='textCenter'>
          <Col className='mt-4'>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 8, offset: 2 }}
              >
                <Button
                  className={
                    !content.secondaryButtonClassName
                      ? 'two-button-modal-button'
                      : `two-button-modal-button ${content.secondaryButtonClassName}`
                  }
                  variant={
                    content.secondaryButtonStyle
                      ? content.secondaryButtonStyle
                      : 'default'
                  }
                  onClick={props.onClickSecondaryButton}
                  disabled={props.isDisabled}
                >
                  {content.secondaryButtonText}
                </Button>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 8, offset: 2 }}
              >
                <Button
                  className={
                    !content.mainButtonClassName
                      ? 'two-button-modal-button'
                      : `two-button-modal-button ${content.mainButtonClassName}`
                  }
                  variant={
                    content.mainButtonStyle
                      ? content.mainButtonStyle
                      : 'primary'
                  }
                  style={{ width: '100%' }}
                  onClick={props.onClickMainButton}
                  disabled={props.isDisabled}
                >
                  {content.mainButtonText}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const checkoutModal = function checkoutModal(props) {
  const content = props.content ? modalContent[props.content] : null;
  return (
    <Modal
      className='modal center-align checkoutModal'
      id={props.id || ''}
      show
      onHide={props.onExit}
      backdrop='static'
    >
      <Modal.Header className='modal-title'>
        {content ? <h1>{content.title}</h1> : null}
        <div className='modal-title-item float-right link'>
          <Button
            onClick={props.onExit}
            variant='incognito'
            aria-label='Close window'
          >
            <FontAwesomeIcon icon={faXmark} className='closeIcon' size='lg' />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className='checkoutModalBody'>
        {props.modalComponent}
      </Modal.Body>
    </Modal>
  );
};

const renderDarkBackdrop = function renderDarkBackdrop(props) {
  if (!props.isShowingModal) return null;
  return <div className='dark-backdrop' />;
};

const renderModal = function renderModal(props) {
  if (!props.isShowingModal) {
    return null;
  }
  if (props.type === 'bodyAndExitModal') {
    return bodyAndExitModal(props);
  }
  if (props.type === 'bodyAndExitModalWithButton') {
    return bodyAndExitModalWithButton(props);
  }
  if (props.type === 'bodyAndExitModalWithTwoButtons') {
    return bodyAndExitModalWithTwoButtons(props);
  }
  if (props.type === 'bodyWithDataAndExitModal') {
    return bodyWithDataAndExitModal(props);
  }
  if (props.type === 'modalWithComponent') {
    return modalWithComponent(props);
  }
  if (props.type === 'checkoutModal') {
    return checkoutModal(props);
  }
  if (props.type === 'componentModalWithTwoButtons') {
    return componentModalWithTwoButtons(props);
  }
  return bodyAndExitModal(props);
};

const BitsyModal = (props) => (
  <div>
    {renderDarkBackdrop(props)}
    {renderModal(props)}
  </div>
);

export default BitsyModal;
